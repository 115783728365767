import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from 'components';

class Index extends Component {
    render() {
        return (
            <Layout>
                <main>
                    <section className="bg-image-hero dark overlay-shape-07 -margin-bottom-2">
                        <div className="full-screen -margin-bottom middle padding padding-top-tablet">
                            <div className="row max-width-l">
                                <div
                                    className="col-one-half middle"
                                    style={{ zIndex: 1 }}
                                >
                                    <h1 className="hero center-tablet">
                                        Applicazioni Cloud per la tua azienda
                                    </h1>
                                    <p className="lead">
                                        Accresci la{' '}
                                        <strong>produttività</strong> della tua
                                        azienda con gli strumenti cloud più{' '}
                                        <strong>sicuri</strong> e
                                        <strong>avanzati</strong> sul mercato.
                                    </p>
                                    <a
                                        href="signup.html"
                                        className="button button-primary button-l space-top"
                                        role="button"
                                    >
                                        Parliamone
                                    </a>
                                </div>
                                <div className="col-one-half middle">
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src="/content/welcome-ten-banner-image.png"
                                            alt="Hero Illustration"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-gradient-light padding">
                        <div className="row margin-bottom max-width-l">
                            <div
                                className="col-one-half middle"
                                style={{ zIndex: 1 }}
                            >
                                <h3>Espandibili</h3>
                                <p className="paragraph">
                                    Li Europan lingues es membres del sam
                                    familie. Lor separat existentie es un myth.
                                    Por scientie, musica, sport etc, litot
                                    Europa usa li sam vocabular. Li lingues
                                    differe solmen in li grammatica, li
                                    pronunciation e li plu commun vocabules.
                                </p>
                            </div>
                            <div className="col-one-half" style={{ zIndex: 1 }}>
                                <img
                                    className="rounded shadow-l"
                                    src="/content/editor.png"
                                    srcSet="
                /content/editor.png    1x,
                /content/editor@2x.png 2x
              "
                                    alt="Editor"
                                />
                            </div>
                        </div>
                        <div className="row margin-bottom max-width-l reverse-order">
                            <div className="col-one-half">
                                <img src="/bg/agency_welcome_3.png" />
                            </div>
                            <div className="col-one-half middle">
                                <h3>Performanti</h3>
                                <p className="paragraph">
                                    Li Europan lingues es membres del sam
                                    familie. Lor separat existentie es un myth.
                                    Por scientie, musica, sport etc, litot
                                    Europa usa li sam vocabular. Li lingues
                                    differe solmen in li grammatica, li
                                    pronunciation e li plu commun vocabules.
                                </p>
                            </div>
                        </div>
                        <div className="row max-width-l" style={{ zIndex: 1 }}>
                            <div className="col-one-half middle">
                                <h3>Semplici</h3>
                                <p className="paragraph">
                                    Li Europan lingues es membres del sam
                                    familie. Lor separat existentie es un myth.
                                    Por scientie, musica, sport etc, litot
                                    Europa usa li sam vocabular. Li lingues
                                    differe solmen in li grammatica, li
                                    pronunciation e li plu commun vocabules.
                                </p>
                            </div>
                            <div className="col-one-half">
                                <img
                                    className="rounded shadow-l"
                                    src="/content/opalin-dashboard.png"
                                    srcSet="
                /content/opalin-dashboard.png    1x,
                /content/opalin-dashboard@2x.png 2x
              "
                                    alt="Opalin Dashboard"
                                />
                            </div>
                        </div>
                    </section>
                    <section className="bg-gradient-dark center dark padding">
                        <div className="max-width-l">
                            <div className="margin-bottom max-width-m">
                                <h3>Over 20,000 Paying Customers</h3>
                                <p className="opacity-m paragraph">
                                    Thousands of the most successful companies
                                    rely on our service.
                                </p>
                            </div>
                            <div className="row min-two-columns">
                                <div className="col-one-fourth">
                                    <img
                                        src="/content/trusted-company-dark.png"
                                        srcSet="
                  /content/trusted-company-dark.png    1x,
                  /content/trusted-company-dark@2x.png 2x
                "
                                        alt="Trusted Company"
                                    />
                                    <br />
                                    <br />
                                    <div className="row no-gutter">
                                        <div className="col middle">
                                            <div className="max-width-m">
                                                <p className="paragraph">
                                                    "Support is fantastic.
                                                    Nothing but great results!"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-one-fourth">
                                    <img
                                        src="/content/trusted-company-dark.png"
                                        srcSet="
                  /content/trusted-company-dark.png    1x,
                  /content/trusted-company-dark@2x.png 2x
                "
                                        alt="Trusted Company"
                                    />
                                    <br />
                                    <br />
                                    <div className="row no-gutter">
                                        <div className="col middle">
                                            <div className="max-width-m">
                                                <p className="paragraph">
                                                    "Support is fantastic.
                                                    Nothing but great results!"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-one-fourth">
                                    <img
                                        src="/content/trusted-company-dark.png"
                                        srcSet="
                  /content/trusted-company-dark.png    1x,
                  /content/trusted-company-dark@2x.png 2x
                "
                                        alt="Trusted Company"
                                    />
                                    <br />
                                    <br />
                                    <div className="row no-gutter">
                                        <div className="col middle">
                                            <div className="max-width-m">
                                                <p className="paragraph">
                                                    "Support is fantastic.
                                                    Nothing but great results!"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-one-fourth">
                                    <img
                                        src="/content/trusted-company-dark.png"
                                        srcSet="
                  /content/trusted-company-dark.png    1x,
                  /content/trusted-company-dark@2x.png 2x
                "
                                        alt="Trusted Company"
                                    />
                                    <br />
                                    <br />
                                    <div className="row no-gutter">
                                        <div className="col middle">
                                            <div className="max-width-m">
                                                <p className="paragraph">
                                                    "Support is fantastic.
                                                    Nothing but great results!"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img
                                className="margin-top rounded shadow-l"
                                src="/content/customers.jpg"
                                srcSet="
              /content/customers.jpg    1x,
              /content/customers@2x.jpg 2x
            "
                                alt="Customers"
                            />
                        </div>
                    </section>
                    <section className="bg-gradient-light padding">
                        <div className="center max-width-l">
                            <h2>Lorem ipsum</h2>
                            <p className="paragraph">
                                At vero eos et accusamus et iusto odio
                                dignissimos ducimus.
                            </p>
                        </div>
                        <div className="row margin-top max-width-l">
                            <div className="col-one-fourth card card-content">
                                <p className="muted">Step 1</p>{' '}
                                <h4>Download</h4>
                                <p className="paragraph">
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat.
                                </p>
                            </div>
                            <div className="col-one-fourth card card-content">
                                <p className="muted">Step 2</p>{' '}
                                <h4>Customize</h4>
                                <p className="paragraph">
                                    Duis aute irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat
                                    nulla pariatur mollit.
                                </p>
                            </div>
                            <div className="col-one-fourth card card-content">
                                <p className="muted">Step 3</p> <h4>Upload</h4>
                                <p className="paragraph">
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </div>
                            <div className="col-one-fourth card card-content dark">
                                <p className="muted">Step 4</p> <h4>Done!</h4>
                                <p className="paragraph">
                                    Sed ut perspiciatis unde omnis iste natus
                                    error sit voluptatem accusantium doloremque
                                    laudantium.
                                </p>
                            </div>
                        </div>
                        <div className="center margin-top max-width-l">
                            <span className="muted">
                                If you need any help, make sure to{' '}
                            </span>
                            <a href="signup.html">book a demo</a>
                            <span className="muted">.</span>
                        </div>
                    </section>
                </main>
            </Layout>
        );
    }
}

export default Index;

Index.propTypes = {
    data: PropTypes.shape({
        posts: PropTypes.object.isRequired
    }).isRequired
};

export const pageQuery = graphql`
    query IndexQuery {
        homepage: prismicHomepage {
            data {
                title {
                    text
                }
                content {
                    html
                }
            }
        }
        social: allPrismicHeroLinksBodyLinkItem {
            edges {
                node {
                    primary {
                        label {
                            text
                        }
                        link {
                            url
                        }
                    }
                }
            }
        }
        posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
            edges {
                node {
                    uid
                    data {
                        title {
                            text
                        }
                        date(formatString: "DD.MM.YYYY")
                        categories {
                            category {
                                document {
                                    data {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        projects: allPrismicProjectsBodyLinkItem {
            edges {
                node {
                    primary {
                        label {
                            text
                        }
                        link {
                            url
                        }
                    }
                }
            }
        }
    }
`;
